import React from "react";
import Layout from "../components/layout";
import OfficeBtn from "../components/officeBtn";

const Logistics = () => {
  return (
    <Layout>
      <div className="container">
        <h1 className="text-center mt-60 mb-30">ОТДЕЛ ЛОГИСТИКИ И ЗАКУПОК</h1>
        <OfficeBtn />
        <div className="region__manager">
          <div className="region_bg">
            <div className="region__position">Менеджер службы логистики</div>
            <ul>
              <li className="region__fio">Трифонов Дмитрий Владимирович</li>
              <li> — офис: +7 (812) 640-30-30, доб. 737, </li>
              <li> — сотовый: +7-967-523-19-61</li>
              <li> — e-mail: trifonovdv@dendor.ru</li>
            </ul>
            <div className="region__position mt-30">
              Начальник отдела доставки
            </div>
            <ul>
              <li className="region__fio">Арбузов Вадим Геннадьевич</li>
              <li> — офис: +7 (812) 640-30-30, доб. 714 </li>
              <li> — сотовый: +7-967-523-19-50</li>
              <li> — e-mail: arbuzov@dendor.ru</li>
            </ul>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Logistics;
